import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141')
];

export const server_loads = [0,2,3,12,13,14,15,16,17,6,8,9,10,11];

export const dictionary = {
		"/(landing)": [~18,[2]],
		"/auth/error": [99],
		"/(legal)/cookies": [19,[3]],
		"/(public)/dana": [~22,[4]],
		"/(public)/directorio-info": [~23,[4]],
		"/error": [100],
		"/error/no-js": [101],
		"/(public)/login": [~24,[4]],
		"/(public)/logout": [25,[4]],
		"/onboarding/clinica": [~110,[12]],
		"/onboarding/new-team": [~111,[12]],
		"/onboarding/paciente": [~112,[12]],
		"/onboarding/[teamId]/completo": [~106,[12]],
		"/onboarding/[teamId]/locations": [~107,[12]],
		"/onboarding/[teamId]/owner": [~108,[12]],
		"/onboarding/[teamId]/plan": [~109,[12]],
		"/onboarding/[teamId]/[professionalId]": [~102,[12]],
		"/onboarding/[teamId]/[professionalId]/end": [~103,[12]],
		"/onboarding/[teamId]/[professionalId]/invoicing": [~104,[12]],
		"/onboarding/[teamId]/[professionalId]/key": [~105,[12]],
		"/paciente": [~113,[13]],
		"/paciente/[patientId]/documents/[docId]": [~114,[13]],
		"/paciente/[patientId]/settings": [~115,[13]],
		"/paddle/pay": [116],
		"/(legal)/politica-privacidad": [20,[3]],
		"/(public)/redirect-home": [~26,[4]],
		"/(public)/registro": [~27,[5]],
		"/(public)/registro/clinica": [~28,[5]],
		"/(public)/registro/paciente": [~29,[5]],
		"/(public)/registro/paciente/[inviteCode]": [~30,[5]],
		"/(public)/registro/paciente/[inviteCode]/completo": [~31,[5]],
		"/(public)/registro/profesional": [~32,[5]],
		"/(public)/registro/profesional/[inviteCode]": [~33,[5]],
		"/(public)/registro/profesional/[inviteCode]/completo": [~34,[5]],
		"/(public)/registro/verificar": [~35,[5]],
		"/(public)/reset-password": [~36,[4]],
		"/superadmin": [117,[14]],
		"/superadmin/testimonials": [~118,[14]],
		"/superadmin/testimonials/new": [~119,[14]],
		"/(legal)/terminos-y-condiciones": [21,[3]],
		"/test/availability": [120,[15]],
		"/test/cal": [121,[15]],
		"/test/docuseal": [~122,[15]],
		"/test/dt": [123,[15]],
		"/test/encryption": [124,[15]],
		"/test/error": [~125,[15]],
		"/test/error/full": [~126,[15]],
		"/test/error/unexpected": [~127,[15]],
		"/test/invites": [128,[15]],
		"/test/invoice-number": [129,[15]],
		"/test/landing": [130,[15,16]],
		"/test/navigation/a": [131,[15,17]],
		"/test/navigation/b": [132,[15,17]],
		"/test/number": [133,[15]],
		"/test/onboarding": [~134,[15]],
		"/test/onboarding/set": [~135,[15]],
		"/test/redirect/a": [136,[15]],
		"/test/redirect/b": [137,[15]],
		"/test/slots": [138,[15]],
		"/test/sms": [139,[15]],
		"/test/supabase": [~140,[15]],
		"/test/transactions": [~141,[15]],
		"/(public)/validate-invite": [37,[4]],
		"/[team]/(public)": [~38,[6,7]],
		"/[team]/admin": [~42,[6,8]],
		"/[team]/admin/analitica": [43,[6,8]],
		"/[team]/admin/calendario": [44,[6,8]],
		"/[team]/admin/configuracion": [~45,[6,8]],
		"/[team]/admin/configuracion/documentos": [~46,[6,8]],
		"/[team]/admin/configuracion/documentos/[id]": [~47,[6,8]],
		"/[team]/admin/configuracion/eholo": [~48,[6,8]],
		"/[team]/admin/configuracion/emails": [~49,[6,8]],
		"/[team]/admin/configuracion/emails/[emailType]": [~50,[6,8]],
		"/[team]/admin/configuracion/finance": [~51,[6,8]],
		"/[team]/admin/configuracion/finance/[legalEntityId]": [~52,[6,8]],
		"/[team]/admin/configuracion/import-v2": [~53,[6,8]],
		"/[team]/admin/configuracion/subscriptions": [~54,[6,8]],
		"/[team]/admin/configuracion/subscriptions/[id]/update-seats": [~55,[6,8]],
		"/[team]/admin/configuracion/templates-docuseal": [~56,[6,8]],
		"/[team]/admin/configuracion/templates-docuseal/new": [~58,[6,8]],
		"/[team]/admin/configuracion/templates-docuseal/[templateId]": [~57,[6,8]],
		"/[team]/admin/equipo": [59,[6,8,9]],
		"/[team]/admin/equipo/invitar": [~61,[6,8,9]],
		"/[team]/admin/equipo/[userId]": [~60,[6,8,9]],
		"/[team]/admin/finances": [~62,[6,8]],
		"/[team]/admin/invalid-subscription": [~63],
		"/[team]/admin/pacientes": [~64,[6,8]],
		"/[team]/admin/pacientes/[patientId]": [~65,[6,8]],
		"/[team]/admin/servicios": [~66,[6,8]],
		"/[team]/admin/sessions": [~67,[6,8]],
		"/[team]/admin/therapies": [~68,[6,8]],
		"/[team]/admin/therapies/new": [~69,[6,8]],
		"/[team]/admin/waitlist": [~70,[6,8]],
		"/[team]/profesional/(dashboard)": [~71,[6,10]],
		"/[team]/profesional/configuracion": [~72,[6,10]],
		"/[team]/profesional/configuracion/encryption": [~73,[6,10]],
		"/[team]/profesional/configuracion/facturacion": [~74,[6,10]],
		"/[team]/profesional/configuracion/import": [75,[6,10]],
		"/[team]/profesional/configuracion/import/eholo": [~76,[6,10]],
		"/[team]/profesional/configuracion/import/google-calendar": [~77,[6,10]],
		"/[team]/profesional/configuracion/import/google-calendar/[calendarId]": [~78,[6,10]],
		"/[team]/profesional/configuracion/import/v2": [~79,[6,10]],
		"/[team]/profesional/finances": [~80,[6,10]],
		"/[team]/profesional/finances/invoices/new": [~82,[6,10]],
		"/[team]/profesional/finances/invoices/[invoiceId]": [~81,[6,10]],
		"/[team]/profesional/invalid-license": [83],
		"/[team]/profesional/notificaciones": [84,[6,10]],
		"/[team]/profesional/sessions": [~85,[6,10]],
		"/[team]/profesional/sessions/[sessionId]/edit": [~86,[6,10]],
		"/[team]/profesional/therapies": [~87,[6,10]],
		"/[team]/profesional/therapies/archived": [~97,[6,10]],
		"/[team]/profesional/therapies/new": [~98,[6,10]],
		"/[team]/profesional/therapies/[therapyId]": [~88,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/documents": [89,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/documents/send": [~91,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/documents/upload": [~92,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/documents/[documentId]": [~90,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/events": [93,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/notes/[noteId]": [~94,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/settings": [~95,[6,10,11]],
		"/[team]/profesional/therapies/[therapyId]/work": [~96,[6,10,11]],
		"/[team]/(public)/p/[professional]": [~39,[6,7]],
		"/[team]/(public)/waitlist": [~40,[6,7]],
		"/[team]/(public)/waitlist/success": [41,[6,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';